
var Rive = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(Rive) {
  Rive = Rive || {};


null;var h;h||(h=typeof Rive !== 'undefined' ? Rive : {});var aa,ba;h.ready=new Promise(function(a,b){aa=a;ba=b});
Rive.onRuntimeInitialized=function(){function a(n){switch(n){case k.srcOver:return"source-over";case k.screen:return"screen";case k.overlay:return"overlay";case k.darken:return"darken";case k.lighten:return"lighten";case k.colorDodge:return"color-dodge";case k.colorBurn:return"color-burn";case k.hardLight:return"hard-light";case k.softLight:return"soft-light";case k.difference:return"difference";case k.exclusion:return"exclusion";case k.multiply:return"multiply";case k.hue:return"hue";case k.saturation:return"saturation";
case k.color:return"color";case k.luminosity:return"luminosity"}}function b(n){return"rgba("+((16711680&n)>>>16)+","+((65280&n)>>>8)+","+((255&n)>>>0)+","+((4278190080&n)>>>24)/255+")"}const c=Rive.RenderPaintStyle,d=Rive.RenderPath,e=Rive.RenderPaint,f=Rive.Renderer,m=Rive.StrokeCap,g=Rive.StrokeJoin,k=Rive.BlendMode,l=c.fill,p=c.stroke,q=Rive.FillRule.evenOdd;var u=Rive.RenderImage.extend("CanvasRenderImage",{__construct:function(){this.__parent.__construct.call(this)},decode:function(n){var t=
this,v=new Image;v.src=URL.createObjectURL(new Blob([n],{type:"image/png"}));v.onload=function(){t.Ja=v;t.size(v.width,v.height)}}}),z=d.extend("CanvasRenderPath",{__construct:function(){this.__parent.__construct.call(this);this.ea=new Path2D},reset:function(){this.ea=new Path2D},addPath:function(n,t){var v=this.ea,w=v.addPath;n=n.ea;const y=new DOMMatrix;y.a=t.xx;y.b=t.xy;y.c=t.yx;y.d=t.yy;y.e=t.tx;y.f=t.ty;w.call(v,n,y)},fillRule:function(n){this.ya=n},moveTo:function(n,t){this.ea.moveTo(n,t)},
lineTo:function(n,t){this.ea.lineTo(n,t)},cubicTo:function(n,t,v,w,y,L){this.ea.bezierCurveTo(n,t,v,w,y,L)},close:function(){this.ea.closePath()}}),x=e.extend("CanvasRenderPaint",{color:function(n){this.za=b(n)},thickness:function(n){this.La=n},join:function(n){switch(n){case g.miter:this.ra="miter";break;case g.round:this.ra="round";break;case g.bevel:this.ra="bevel"}},cap:function(n){switch(n){case m.butt:this.qa="butt";break;case m.round:this.qa="round";break;case m.square:this.qa="square"}},style:function(n){this.Ka=
n},blendMode:function(n){this.Ia=a(n)},linearGradient:function(n,t,v,w){this.na={Ga:n,Ha:t,Ba:v,Ca:w,wa:[]}},radialGradient:function(n,t,v,w){this.na={Ga:n,Ha:t,Ba:v,Ca:w,wa:[],Va:!0}},addStop:function(n,t){this.na.wa.push({color:n,stop:t})},completeGradient:function(){},draw:function(n,t){let v=this.Ka;var w=this.za,y=this.na;n.globalCompositeOperation=this.Ia;if(null!=y){w=y.Ga;var L=y.Ha;const cb=y.Ba;var sa=y.Ca;const db=y.wa;y.Va?(y=cb-w,sa-=L,w=n.createRadialGradient(w,L,0,w,L,Math.sqrt(y*y+
sa*sa))):w=n.createLinearGradient(w,L,cb,sa);for(let Ma=0,Gb=db.length;Ma<Gb;Ma++)L=db[Ma],w.addColorStop(L.stop,b(L.color));this.za=w;this.na=null}switch(v){case p:n.strokeStyle=w;n.lineWidth=this.La;n.lineCap=this.qa;n.lineJoin=this.ra;n.stroke(t.ea);break;case l:n.fillStyle=w,n.fill(t.ea,t.ya===q?"evenodd":"nonzero")}}}),I=Rive.CanvasRenderer=f.extend("Renderer",{__construct:function(n){this.__parent.__construct.call(this);this.ha=n.getContext("2d");this.xa=n},save:function(){this.ha.save()},restore:function(){this.ha.restore()},
transform:function(n){this.ha.transform(n.xx,n.xy,n.yx,n.yy,n.tx,n.ty)},drawPath:function(n,t){t.draw(this.ha,n)},drawImage:function(n,t,v){if(n=n.Ja){var w=this.ha;w.globalCompositeOperation=a(t);w.globalAlpha=v;w.drawImage(n,0,0);w.globalAlpha=1}},clipPath:function(n){this.ha.clip(n.ea,n.ya===q?"evenodd":"nonzero")},clear:function(){this.ha.clearRect(0,0,this.xa.width,this.xa.height)},flush:function(){}});Rive.makeRenderer=function(n){return new I(n)};Rive.renderFactory={makeRenderPaint:function(){return new x},
makeRenderPath:function(){return new z},makeRenderImage:function(){return new u}}};var ca={},r;for(r in h)h.hasOwnProperty(r)&&(ca[r]=h[r]);var da="object"===typeof window,ea="function"===typeof importScripts,A="",fa,ha,ia,B,C;
if("object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node)A=ea?require("path").dirname(A)+"/":__dirname+"/",fa=function(a,b){B||(B=require("fs"));C||(C=require("path"));a=C.normalize(a);return B.readFileSync(a,b?null:"utf8")},ia=function(a){a=fa(a,!0);a.buffer||(a=new Uint8Array(a));a.buffer||D("Assertion failed: undefined");return a},ha=function(a,b,c){B||(B=require("fs"));C||(C=require("path"));a=C.normalize(a);B.readFile(a,function(d,e){d?c(d):b(e.buffer)})},
1<process.argv.length&&process.argv[1].replace(/\\/g,"/"),process.argv.slice(2),process.on("uncaughtException",function(a){throw a;}),process.on("unhandledRejection",function(a){throw a;}),h.inspect=function(){return"[Emscripten Module object]"};else if(da||ea)ea?A=self.location.href:"undefined"!==typeof document&&document.currentScript&&(A=document.currentScript.src),_scriptDir&&(A=_scriptDir),0!==A.indexOf("blob:")?A=A.substr(0,A.replace(/[?#].*/,"").lastIndexOf("/")+1):A="",fa=function(a){var b=
new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},ea&&(ia=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),ha=function(a,b,c){var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=function(){200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)};var ja=h.print||console.log.bind(console),ka=h.printErr||console.warn.bind(console);
for(r in ca)ca.hasOwnProperty(r)&&(h[r]=ca[r]);ca=null;var la;h.wasmBinary&&(la=h.wasmBinary);var noExitRuntime=h.noExitRuntime||!0;"object"!==typeof WebAssembly&&D("no native wasm support detected");var ma,na=!1,oa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function pa(a,b,c){var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.subarray&&oa)return oa.decode(a.subarray(b,c));for(d="";b<c;){var e=a[b++];if(e&128){var f=a[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var m=a[b++]&63;e=224==(e&240)?(e&15)<<12|f<<6|m:(e&7)<<18|f<<12|m<<6|a[b++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d}
function qa(a,b,c){var d=E;if(0<c){c=b+c-1;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var m=a.charCodeAt(++e);f=65536+((f&1023)<<10)|m&1023}if(127>=f){if(b>=c)break;d[b++]=f}else{if(2047>=f){if(b+1>=c)break;d[b++]=192|f>>6}else{if(65535>=f){if(b+2>=c)break;d[b++]=224|f>>12}else{if(b+3>=c)break;d[b++]=240|f>>18;d[b++]=128|f>>12&63}d[b++]=128|f>>6&63}d[b++]=128|f&63}}d[b]=0}}var ra="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;
function ta(a,b){var c=a>>1;for(var d=c+b/2;!(c>=d)&&ua[c];)++c;c<<=1;if(32<c-a&&ra)return ra.decode(E.subarray(a,c));c="";for(d=0;!(d>=b/2);++d){var e=F[a+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c}function va(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var e=0;e<c;++e)F[b>>1]=a.charCodeAt(e),b+=2;F[b>>1]=0;return b-d}function wa(a){return 2*a.length}
function xa(a,b){for(var c=0,d="";!(c>=b/4);){var e=G[a+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d}function ya(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var d=b;c=d+c-4;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var m=a.charCodeAt(++e);f=65536+((f&1023)<<10)|m&1023}G[b>>2]=f;b+=4;if(b+4>c)break}G[b>>2]=0;return b-d}
function za(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b}var Aa,Ba,E,F,ua,G,H,Ca,Da;function Ea(){var a=ma.buffer;Aa=a;h.HEAP8=Ba=new Int8Array(a);h.HEAP16=F=new Int16Array(a);h.HEAP32=G=new Int32Array(a);h.HEAPU8=E=new Uint8Array(a);h.HEAPU16=ua=new Uint16Array(a);h.HEAPU32=H=new Uint32Array(a);h.HEAPF32=Ca=new Float32Array(a);h.HEAPF64=Da=new Float64Array(a)}var Fa,Ga=[],Ha=[],Ia=[];function Ja(){var a=h.preRun.shift();Ga.unshift(a)}
var J=0,Ka=null,La=null;h.preloadedImages={};h.preloadedAudios={};function D(a){if(h.onAbort)h.onAbort(a);a="Aborted("+a+")";ka(a);na=!0;a=new WebAssembly.RuntimeError(a+". Build with -s ASSERTIONS=1 for more info.");ba(a);throw a;}function Na(){return K.startsWith("data:application/octet-stream;base64,")}var K;K="canvas_advanced.wasm";if(!Na()){var Oa=K;K=h.locateFile?h.locateFile(Oa,A):A+Oa}
function Pa(){var a=K;try{if(a==K&&la)return new Uint8Array(la);if(ia)return ia(a);throw"both async and sync fetching of the wasm failed";}catch(b){D(b)}}
function Qa(){if(!la&&(da||ea)){if("function"===typeof fetch&&!K.startsWith("file://"))return fetch(K,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+K+"'";return a.arrayBuffer()}).catch(function(){return Pa()});if(ha)return new Promise(function(a,b){ha(K,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Pa()})}
function Ra(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(h);else{var c=b.cb;"number"===typeof c?void 0===b.sa?Fa.get(c)():Fa.get(c)(b.sa):c(void 0===b.sa?null:b.sa)}}}function Sa(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}function Ta(a,b){a=Sa(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}
var M=[{},{value:void 0},{value:null},{value:!0},{value:!1}],Ua=[];function Va(a){var b=Error,c=Ta(a,function(d){this.name=a;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}var Wa=void 0;function N(a){throw new Wa(a);}
function O(a){a||N("Cannot use deleted val. handle = "+a);return M[a].value}function P(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=Ua.length?Ua.pop():M.length;M[b]={va:1,value:a};return b}}var Xa=void 0,Ya=void 0;function Q(a){for(var b="";E[a];)b+=Ya[E[a++]];return b}var Za=[];function $a(){for(;Za.length;){var a=Za.pop();a.V.ka=!1;a["delete"]()}}var ab=void 0,R={};
function bb(a,b){for(void 0===b&&N("ptr should not be undefined");a.$;)b=a.ma(b),a=a.$;return b}var S={};function eb(a){a=fb(a);var b=Q(a);T(a);return b}function gb(a,b){var c=S[a];void 0===c&&N(b+" has unknown type "+eb(a));return c}function hb(){}var ib=!1;function jb(a){--a.count.value;0===a.count.value&&(a.aa?a.da.ga(a.aa):a.Y.W.ga(a.X))}
function kb(a){if("undefined"===typeof FinalizationGroup)return kb=function(b){return b},a;ib=new FinalizationGroup(function(b){for(var c=b.next();!c.done;c=b.next())c=c.value,c.X?jb(c):console.warn("object already deleted: "+c.X)});kb=function(b){ib.register(b,b.V,b.V);return b};hb=function(b){ib.unregister(b.V)};return kb(a)}var lb={};function mb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function nb(a){return this.fromWireType(H[a>>2])}var ob={},pb={},qb=void 0;
function rb(a){throw new qb(a);}function U(a,b,c){function d(g){g=c(g);g.length!==a.length&&rb("Mismatched type converter count");for(var k=0;k<a.length;++k)V(a[k],g[k])}a.forEach(function(g){pb[g]=b});var e=Array(b.length),f=[],m=0;b.forEach(function(g,k){S.hasOwnProperty(g)?e[k]=S[g]:(f.push(g),ob.hasOwnProperty(g)||(ob[g]=[]),ob[g].push(function(){e[k]=S[g];++m;m===f.length&&d(e)}))});0===f.length&&d(e)}
function sb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}
function V(a,b,c){c=c||{};if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=b.name;a||N('type "'+d+'" must have a positive integer typeid pointer');if(S.hasOwnProperty(a)){if(c.Ua)return;N("Cannot register type '"+d+"' twice")}S[a]=b;delete pb[a];ob.hasOwnProperty(a)&&(b=ob[a],delete ob[a],b.forEach(function(e){e()}))}function tb(a){N(a.V.Y.W.name+" instance already deleted")}function W(){}var ub={};
function vb(a,b,c){if(void 0===a[b].Z){var d=a[b];a[b]=function(){a[b].Z.hasOwnProperty(arguments.length)||N("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].Z+")!");return a[b].Z[arguments.length].apply(this,arguments)};a[b].Z=[];a[b].Z[d.ja]=d}}
function wb(a,b,c){h.hasOwnProperty(a)?((void 0===c||void 0!==h[a].Z&&void 0!==h[a].Z[c])&&N("Cannot register public name '"+a+"' twice"),vb(h,a,a),h.hasOwnProperty(c)&&N("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),h[a].Z[c]=b):(h[a]=b,void 0!==c&&(h[a].eb=c))}function xb(a,b,c,d,e,f,m,g){this.name=a;this.constructor=b;this.fa=c;this.ga=d;this.$=e;this.Pa=f;this.ma=m;this.Na=g;this.Ea=[]}
function yb(a,b,c){for(;b!==c;)b.ma||N("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.ma(a),b=b.$;return a}function zb(a,b){if(null===b)return this.ta&&N("null is not a valid "+this.name),0;b.V||N('Cannot pass "'+Ab(b)+'" as a '+this.name);b.V.X||N("Cannot pass deleted object as a pointer of type "+this.name);return yb(b.V.X,b.V.Y.W,this.W)}
function Bb(a,b){if(null===b){this.ta&&N("null is not a valid "+this.name);if(this.pa){var c=this.ua();null!==a&&a.push(this.ga,c);return c}return 0}b.V||N('Cannot pass "'+Ab(b)+'" as a '+this.name);b.V.X||N("Cannot pass deleted object as a pointer of type "+this.name);!this.oa&&b.V.Y.oa&&N("Cannot convert argument of type "+(b.V.da?b.V.da.name:b.V.Y.name)+" to parameter type "+this.name);c=yb(b.V.X,b.V.Y.W,this.W);if(this.pa)switch(void 0===b.V.aa&&N("Passing raw pointer to smart pointer is illegal"),
this.bb){case 0:b.V.da===this?c=b.V.aa:N("Cannot convert argument of type "+(b.V.da?b.V.da.name:b.V.Y.name)+" to parameter type "+this.name);break;case 1:c=b.V.aa;break;case 2:if(b.V.da===this)c=b.V.aa;else{var d=b.clone();c=this.Ya(c,P(function(){d["delete"]()}));null!==a&&a.push(this.ga,c)}break;default:N("Unsupporting sharing policy")}return c}
function Cb(a,b){if(null===b)return this.ta&&N("null is not a valid "+this.name),0;b.V||N('Cannot pass "'+Ab(b)+'" as a '+this.name);b.V.X||N("Cannot pass deleted object as a pointer of type "+this.name);b.V.Y.oa&&N("Cannot convert argument of type "+b.V.Y.name+" to parameter type "+this.name);return yb(b.V.X,b.V.Y.W,this.W)}function Db(a,b,c){if(b===c)return a;if(void 0===c.$)return null;a=Db(a,b,c.$);return null===a?null:c.Na(a)}function Eb(a,b){b=bb(a,b);return R[b]}
function Fb(a,b){b.Y&&b.X||rb("makeClassHandle requires ptr and ptrType");!!b.da!==!!b.aa&&rb("Both smartPtrType and smartPtr must be specified");b.count={value:1};return kb(Object.create(a,{V:{value:b}}))}function X(a,b,c,d){this.name=a;this.W=b;this.ta=c;this.oa=d;this.pa=!1;this.ga=this.Ya=this.ua=this.Fa=this.bb=this.Xa=void 0;void 0!==b.$?this.toWireType=Bb:(this.toWireType=d?zb:Cb,this.ba=null)}
function Hb(a,b,c){h.hasOwnProperty(a)||rb("Replacing nonexistant public symbol");void 0!==h[a].Z&&void 0!==c?h[a].Z[c]=b:(h[a]=b,h[a].ja=c)}function Ib(a,b){var c=[];return function(){c.length=arguments.length;for(var d=0;d<arguments.length;d++)c[d]=arguments[d];a.includes("j")?(d=h["dynCall_"+a],d=c&&c.length?d.apply(null,[b].concat(c)):d.call(null,b)):d=Fa.get(b).apply(null,c);return d}}
function Y(a,b){a=Q(a);var c=a.includes("j")?Ib(a,b):Fa.get(b);"function"!==typeof c&&N("unknown function pointer with signature "+a+": "+b);return c}var Jb=void 0;function Z(a,b){function c(f){e[f]||S[f]||(pb[f]?pb[f].forEach(c):(d.push(f),e[f]=!0))}var d=[],e={};b.forEach(c);throw new Jb(a+": "+d.map(eb).join([", "]));}
function Kb(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=Ta(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function Lb(a,b,c,d,e){var f=b.length;2>f&&N("argTypes array size mismatch! Must at least get return value and 'this' types!");var m=null!==b[1]&&null!==c,g=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].ba){g=!0;break}var k="void"!==b[0].name,l="",p="";for(c=0;c<f-2;++c)l+=(0!==c?", ":"")+"arg"+c,p+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+Sa(a)+"("+l+") {\nif (arguments.length !== "+(f-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(f-2)+" args!');\n}\n";g&&(a+="var destructors = [];\n");var q=g?"destructors":"null";l="throwBindingError invoker fn runDestructors retType classParam".split(" ");d=[N,d,e,mb,b[0],b[1]];m&&(a+="var thisWired = classParam.toWireType("+q+", this);\n");for(c=0;c<f-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+q+", arg"+c+"); // "+b[c+2].name+"\n",l.push("argType"+c),d.push(b[c+2]);m&&(p="thisWired"+(0<p.length?", ":"")+p);a+=(k?"var rv = ":"")+"invoker(fn"+(0<p.length?", ":"")+p+");\n";if(g)a+=
"runDestructors(destructors);\n";else for(c=m?1:2;c<b.length;++c)f=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].ba&&(a+=f+"_dtor("+f+"); // "+b[c].name+"\n",l.push(f+"_dtor"),d.push(b[c].ba));k&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");l.push(a+"}\n");return Kb(l).apply(null,d)}function Mb(a,b){for(var c=[],d=0;d<a;d++)c.push(G[(b>>2)+d]);return c}
function Nb(a,b,c){a instanceof Object||N(c+' with invalid "this": '+a);a instanceof b.W.constructor||N(c+' incompatible with "this" of type '+a.constructor.name);a.V.X||N("cannot call emscripten binding method "+c+" on deleted object");return yb(a.V.X,a.V.Y.W,b.W)}function Ob(a){4<a&&0===--M[a].va&&(M[a]=void 0,Ua.push(a))}
function Pb(a,b,c){switch(b){case 0:return function(d){return this.fromWireType((c?Ba:E)[d])};case 1:return function(d){return this.fromWireType((c?F:ua)[d>>1])};case 2:return function(d){return this.fromWireType((c?G:H)[d>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function Ab(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function Qb(a,b){switch(b){case 2:return function(c){return this.fromWireType(Ca[c>>2])};case 3:return function(c){return this.fromWireType(Da[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Rb(a,b,c){switch(b){case 0:return c?function(d){return Ba[d]}:function(d){return E[d]};case 1:return c?function(d){return F[d>>1]}:function(d){return ua[d>>1]};case 2:return c?function(d){return G[d>>2]}:function(d){return H[d>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var Sb={};function Tb(a){var b=Sb[a];return void 0===b?Q(a):b}var Ub=[];function Vb(a){var b=Ub.length;Ub.push(a);return b}
function Wb(a,b){for(var c=Array(a),d=0;d<a;++d)c[d]=gb(G[(b>>2)+d],"parameter "+d);return c}var Xb=[],Yb=[null,[],[]];Wa=h.BindingError=Va("BindingError");h.count_emval_handles=function(){for(var a=0,b=5;b<M.length;++b)void 0!==M[b]&&++a;return a};h.get_first_emval=function(){for(var a=5;a<M.length;++a)if(void 0!==M[a])return M[a];return null};Xa=h.PureVirtualError=Va("PureVirtualError");for(var Zb=Array(256),$b=0;256>$b;++$b)Zb[$b]=String.fromCharCode($b);Ya=Zb;h.getInheritedInstanceCount=function(){return Object.keys(R).length};
h.getLiveInheritedInstances=function(){var a=[],b;for(b in R)R.hasOwnProperty(b)&&a.push(R[b]);return a};h.flushPendingDeletes=$a;h.setDelayFunction=function(a){ab=a;Za.length&&ab&&ab($a)};qb=h.InternalError=Va("InternalError");W.prototype.isAliasOf=function(a){if(!(this instanceof W&&a instanceof W))return!1;var b=this.V.Y.W,c=this.V.X,d=a.V.Y.W;for(a=a.V.X;b.$;)c=b.ma(c),b=b.$;for(;d.$;)a=d.ma(a),d=d.$;return b===d&&c===a};
W.prototype.clone=function(){this.V.X||tb(this);if(this.V.la)return this.V.count.value+=1,this;var a=kb,b=Object,c=b.create,d=Object.getPrototypeOf(this),e=this.V;a=a(c.call(b,d,{V:{value:{count:e.count,ka:e.ka,la:e.la,X:e.X,Y:e.Y,aa:e.aa,da:e.da}}}));a.V.count.value+=1;a.V.ka=!1;return a};W.prototype["delete"]=function(){this.V.X||tb(this);this.V.ka&&!this.V.la&&N("Object already scheduled for deletion");hb(this);jb(this.V);this.V.la||(this.V.aa=void 0,this.V.X=void 0)};W.prototype.isDeleted=function(){return!this.V.X};
W.prototype.deleteLater=function(){this.V.X||tb(this);this.V.ka&&!this.V.la&&N("Object already scheduled for deletion");Za.push(this);1===Za.length&&ab&&ab($a);this.V.ka=!0;return this};X.prototype.Qa=function(a){this.Fa&&(a=this.Fa(a));return a};X.prototype.Aa=function(a){this.ga&&this.ga(a)};X.prototype.argPackAdvance=8;X.prototype.readValueFromPointer=nb;X.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
X.prototype.fromWireType=function(a){function b(){return this.pa?Fb(this.W.fa,{Y:this.Xa,X:c,da:this,aa:a}):Fb(this.W.fa,{Y:this,X:a})}var c=this.Qa(a);if(!c)return this.Aa(a),null;var d=Eb(this.W,c);if(void 0!==d){if(0===d.V.count.value)return d.V.X=c,d.V.aa=a,d.clone();d=d.clone();this.Aa(a);return d}d=this.W.Pa(c);d=ub[d];if(!d)return b.call(this);d=this.oa?d.Ma:d.pointerType;var e=Db(c,this.W,d.W);return null===e?b.call(this):this.pa?Fb(d.W.fa,{Y:d,X:e,da:this,aa:a}):Fb(d.W.fa,{Y:d,X:e})};
Jb=h.UnboundTypeError=Va("UnboundTypeError");
var bc={m:function(a,b,c){a=Q(a);b=gb(b,"wrapper");c=O(c);var d=[].slice,e=b.W,f=e.fa,m=e.$.fa,g=e.$.constructor;a=Ta(a,function(){e.$.Ea.forEach(function(l){if(this[l]===m[l])throw new Xa("Pure virtual function "+l+" must be implemented in JavaScript");}.bind(this));Object.defineProperty(this,"__parent",{value:f});this.__construct.apply(this,d.call(arguments))});f.__construct=function(){this===f&&N("Pass correct 'this' to __construct");var l=g.implement.apply(void 0,[this].concat(d.call(arguments)));
hb(l);var p=l.V;l.notifyOnDestruction();p.la=!0;Object.defineProperties(this,{V:{value:p}});kb(this);l=p.X;l=bb(e,l);R.hasOwnProperty(l)?N("Tried to register registered instance: "+l):R[l]=this};f.__destruct=function(){this===f&&N("Pass correct 'this' to __destruct");hb(this);var l=this.V.X;l=bb(e,l);R.hasOwnProperty(l)?delete R[l]:N("Tried to unregister unregistered instance: "+l)};a.prototype=Object.create(f);for(var k in c)a.prototype[k]=c[k];return P(a)},H:function(a){var b=lb[a];delete lb[a];
var c=b.ua,d=b.ga,e=b.Da,f=e.map(function(m){return m.Ta}).concat(e.map(function(m){return m.$a}));U([a],f,function(m){var g={};e.forEach(function(k,l){var p=m[l],q=k.Ra,u=k.Sa,z=m[l+e.length],x=k.Za,I=k.ab;g[k.Oa]={read:function(n){return p.fromWireType(q(u,n))},write:function(n,t){var v=[];x(I,n,z.toWireType(v,t));mb(v)}}});return[{name:b.name,fromWireType:function(k){var l={},p;for(p in g)l[p]=g[p].read(k);d(k);return l},toWireType:function(k,l){for(var p in g)if(!(p in l))throw new TypeError('Missing field:  "'+
p+'"');var q=c();for(p in g)g[p].write(q,l[p]);null!==k&&k.push(d,q);return q},argPackAdvance:8,readValueFromPointer:nb,ba:d}]})},y:function(){},E:function(a,b,c,d,e){var f=sb(c);b=Q(b);V(a,{name:b,fromWireType:function(m){return!!m},toWireType:function(m,g){return g?d:e},argPackAdvance:8,readValueFromPointer:function(m){if(1===c)var g=Ba;else if(2===c)g=F;else if(4===c)g=G;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(g[m>>f])},ba:null})},c:function(a,b,c,d,e,
f,m,g,k,l,p,q,u){p=Q(p);f=Y(e,f);g&&(g=Y(m,g));l&&(l=Y(k,l));u=Y(q,u);var z=Sa(p);wb(z,function(){Z("Cannot construct "+p+" due to unbound types",[d])});U([a,b,c],d?[d]:[],function(x){x=x[0];if(d){var I=x.W;var n=I.fa}else n=W.prototype;x=Ta(z,function(){if(Object.getPrototypeOf(this)!==t)throw new Wa("Use 'new' to construct "+p);if(void 0===v.ia)throw new Wa(p+" has no accessible constructor");var y=v.ia[arguments.length];if(void 0===y)throw new Wa("Tried to invoke ctor of "+p+" with invalid number of parameters ("+
arguments.length+") - expected ("+Object.keys(v.ia).toString()+") parameters instead!");return y.apply(this,arguments)});var t=Object.create(n,{constructor:{value:x}});x.prototype=t;var v=new xb(p,x,t,u,I,f,g,l);I=new X(p,v,!0,!1);n=new X(p+"*",v,!1,!1);var w=new X(p+" const*",v,!1,!0);ub[a]={pointerType:n,Ma:w};Hb(z,x);return[I,n,w]})},i:function(a,b,c,d,e,f,m){var g=Mb(c,d);b=Q(b);f=Y(e,f);U([],[a],function(k){function l(){Z("Cannot call "+p+" due to unbound types",g)}k=k[0];var p=k.name+"."+b;
b.startsWith("@@")&&(b=Symbol[b.substring(2)]);var q=k.W.constructor;void 0===q[b]?(l.ja=c-1,q[b]=l):(vb(q,b,p),q[b].Z[c-1]=l);U([],g,function(u){u=[u[0],null].concat(u.slice(1));u=Lb(p,u,null,f,m);void 0===q[b].Z?(u.ja=c-1,q[b]=u):q[b].Z[c-1]=u;return[]});return[]})},f:function(a,b,c,d,e,f,m,g){b=Q(b);f=Y(e,f);U([],[a],function(k){k=k[0];var l=k.name+"."+b,p={get:function(){Z("Cannot access "+l+" due to unbound types",[c])},enumerable:!0,configurable:!0};p.set=g?function(){Z("Cannot access "+l+" due to unbound types",
[c])}:function(){N(l+" is a read-only property")};Object.defineProperty(k.W.constructor,b,p);U([],[c],function(q){q=q[0];var u={get:function(){return q.fromWireType(f(d))},enumerable:!0};g&&(g=Y(m,g),u.set=function(z){var x=[];g(d,q.toWireType(x,z));mb(x)});Object.defineProperty(k.W.constructor,b,u);return[]});return[]})},p:function(a,b,c,d,e,f){0<b||D("Assertion failed: undefined");var m=Mb(b,c);e=Y(d,e);U([],[a],function(g){g=g[0];var k="constructor "+g.name;void 0===g.W.ia&&(g.W.ia=[]);if(void 0!==
g.W.ia[b-1])throw new Wa("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+g.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");g.W.ia[b-1]=function(){Z("Cannot construct "+g.name+" due to unbound types",m)};U([],m,function(l){l.splice(1,0,null);g.W.ia[b-1]=Lb(k,l,null,e,f);return[]});return[]})},a:function(a,b,c,d,e,f,m,g){var k=Mb(c,d);b=Q(b);f=Y(e,f);U([],[a],function(l){function p(){Z("Cannot call "+
q+" due to unbound types",k)}l=l[0];var q=l.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);g&&l.W.Ea.push(b);var u=l.W.fa,z=u[b];void 0===z||void 0===z.Z&&z.className!==l.name&&z.ja===c-2?(p.ja=c-2,p.className=l.name,u[b]=p):(vb(u,b,q),u[b].Z[c-2]=p);U([],k,function(x){x=Lb(q,x,l,f,m);void 0===u[b].Z?(x.ja=c-2,u[b]=x):u[b].Z[c-2]=x;return[]});return[]})},b:function(a,b,c,d,e,f,m,g,k,l){b=Q(b);e=Y(d,e);U([],[a],function(p){p=p[0];var q=p.name+"."+b,u={get:function(){Z("Cannot access "+q+
" due to unbound types",[c,m])},enumerable:!0,configurable:!0};u.set=k?function(){Z("Cannot access "+q+" due to unbound types",[c,m])}:function(){N(q+" is a read-only property")};Object.defineProperty(p.W.fa,b,u);U([],k?[c,m]:[c],function(z){var x=z[0],I={get:function(){var t=Nb(this,p,q+" getter");return x.fromWireType(e(f,t))},enumerable:!0};if(k){k=Y(g,k);var n=z[1];I.set=function(t){var v=Nb(this,p,q+" setter"),w=[];k(l,v,n.toWireType(w,t));mb(w)}}Object.defineProperty(p.W.fa,b,I);return[]});
return[]})},D:function(a,b){b=Q(b);V(a,{name:b,fromWireType:function(c){var d=O(c);Ob(c);return d},toWireType:function(c,d){return P(d)},argPackAdvance:8,readValueFromPointer:nb,ba:null})},k:function(a,b,c,d){function e(){}c=sb(c);b=Q(b);e.values={};V(a,{name:b,constructor:e,fromWireType:function(f){return this.constructor.values[f]},toWireType:function(f,m){return m.value},argPackAdvance:8,readValueFromPointer:Pb(b,c,d),ba:null});wb(b,e)},j:function(a,b,c){var d=gb(a,"enum");b=Q(b);a=d.constructor;
d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:Ta(d.name+"_"+b,function(){})}});a.values[c]=d;a[b]=d},r:function(a,b,c){c=sb(c);b=Q(b);V(a,{name:b,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:Qb(b,c),ba:null})},K:function(a,b,c,d,e,f){var m=Mb(b,c);a=Q(a);e=Y(d,e);wb(a,function(){Z("Cannot call "+a+" due to unbound types",m)},b-1);U([],m,function(g){g=[g[0],null].concat(g.slice(1));Hb(a,Lb(a,g,null,e,f),b-
1);return[]})},h:function(a,b,c,d,e){function f(l){return l}b=Q(b);-1===e&&(e=4294967295);var m=sb(c);if(0===d){var g=32-8*c;f=function(l){return l<<g>>>g}}var k=b.includes("unsigned");V(a,{name:b,fromWireType:f,toWireType:function(l,p){if("number"!==typeof p&&"boolean"!==typeof p)throw new TypeError('Cannot convert "'+Ab(p)+'" to '+this.name);if(p<d||p>e)throw new TypeError('Passing a number "'+Ab(p)+'" from JS side to C/C++ side to an argument of type "'+b+'", which is outside the valid range ['+
d+", "+e+"]!");return k?p>>>0:p|0},argPackAdvance:8,readValueFromPointer:Rb(b,m,0!==d),ba:null})},g:function(a,b,c){function d(f){f>>=2;var m=H;return new e(Aa,m[f+1],m[f])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=Q(c);V(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{Ua:!0})},s:function(a,b){b=Q(b);var c="std::string"===b;V(a,{name:b,fromWireType:function(d){var e=H[d>>2];if(c)for(var f=d+4,m=0;m<=e;++m){var g=d+
4+m;if(m==e||0==E[g]){f=f?pa(E,f,g-f):"";if(void 0===k)var k=f;else k+=String.fromCharCode(0),k+=f;f=g+1}}else{k=Array(e);for(m=0;m<e;++m)k[m]=String.fromCharCode(E[d+4+m]);k=k.join("")}T(d);return k},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var f="string"===typeof e;f||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array||N("Cannot pass non-string to std::string");var m=(c&&f?function(){for(var l=0,p=0;p<e.length;++p){var q=e.charCodeAt(p);
55296<=q&&57343>=q&&(q=65536+((q&1023)<<10)|e.charCodeAt(++p)&1023);127>=q?++l:l=2047>=q?l+2:65535>=q?l+3:l+4}return l}:function(){return e.length})(),g=ac(4+m+1);H[g>>2]=m;if(c&&f)qa(e,g+4,m+1);else if(f)for(f=0;f<m;++f){var k=e.charCodeAt(f);255<k&&(T(g),N("String has UTF-16 code units that do not fit in 8 bits"));E[g+4+f]=k}else for(f=0;f<m;++f)E[g+4+f]=e[f];null!==d&&d.push(T,g);return g},argPackAdvance:8,readValueFromPointer:nb,ba:function(d){T(d)}})},o:function(a,b,c){c=Q(c);if(2===b){var d=
ta;var e=va;var f=wa;var m=function(){return ua};var g=1}else 4===b&&(d=xa,e=ya,f=za,m=function(){return H},g=2);V(a,{name:c,fromWireType:function(k){for(var l=H[k>>2],p=m(),q,u=k+4,z=0;z<=l;++z){var x=k+4+z*b;if(z==l||0==p[x>>g])u=d(u,x-u),void 0===q?q=u:(q+=String.fromCharCode(0),q+=u),u=x+b}T(k);return q},toWireType:function(k,l){"string"!==typeof l&&N("Cannot pass non-string to C++ string type "+c);var p=f(l),q=ac(4+p+b);H[q>>2]=p>>g;e(l,q+4,p+b);null!==k&&k.push(T,q);return q},argPackAdvance:8,
readValueFromPointer:nb,ba:function(k){T(k)}})},I:function(a,b,c,d,e,f){lb[a]={name:Q(b),ua:Y(c,d),ga:Y(e,f),Da:[]}},G:function(a,b,c,d,e,f,m,g,k,l){lb[a].Da.push({Oa:Q(b),Ta:c,Ra:Y(d,e),Sa:f,$a:m,Za:Y(g,k),ab:l})},F:function(a,b){b=Q(b);V(a,{Wa:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},l:function(a,b,c){a=O(a);b=gb(b,"emval::as");var d=[],e=P(d);G[c>>2]=e;return b.toWireType(d,a)},q:function(a,b,c,d,e){a=Ub[a];b=O(b);c=Tb(c);var f=[];G[d>>2]=P(f);return a(b,
c,f,e)},e:function(a,b,c,d){a=Ub[a];b=O(b);c=Tb(c);a(b,c,null,d)},J:Ob,d:function(a,b){var c=Wb(a,b),d=c[0];b=d.name+"_$"+c.slice(1).map(function(p){return p.name}).join("_")+"$";var e=Xb[b];if(void 0!==e)return e;e=["retType"];for(var f=[d],m="",g=0;g<a-1;++g)m+=(0!==g?", ":"")+"arg"+g,e.push("argType"+g),f.push(c[1+g]);var k="return function "+Sa("methodCaller_"+b)+"(handle, name, destructors, args) {\n",l=0;for(g=0;g<a-1;++g)k+="    var arg"+g+" = argType"+g+".readValueFromPointer(args"+(l?"+"+
l:"")+");\n",l+=c[g+1].argPackAdvance;k+="    var rv = handle[name]("+m+");\n";for(g=0;g<a-1;++g)c[g+1].deleteObject&&(k+="    argType"+g+".deleteObject(arg"+g+");\n");d.Wa||(k+="    return retType.toWireType(destructors, rv);\n");e.push(k+"};\n");a=Kb(e).apply(null,f);e=Vb(a);return Xb[b]=e},M:function(a){a=Tb(a);return P(h[a])},v:function(a,b){a=O(a);b=O(b);return P(a[b])},L:function(a){4<a&&(M[a].va+=1)},u:function(a){return P(Tb(a))},w:function(a){var b=O(a);mb(b);Ob(a)},C:function(a,b){a=gb(a,
"_emval_take_value");a=a.readValueFromPointer(b);return P(a)},t:function(){D("")},n:function(a){var b=E.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);d=Math.max(a,d);0<d%65536&&(d+=65536-d%65536);a:{try{ma.grow(Math.min(2147483648,d)-Aa.byteLength+65535>>>16);Ea();var e=1;break a}catch(f){}e=void 0}if(e)return!0}return!1},B:function(){return 0},x:function(){},A:function(a,b,c,d){for(var e=0,f=0;f<c;f++){var m=G[b>>2],g=G[b+4>>2];b+=8;for(var k=
0;k<g;k++){var l=E[m+k],p=Yb[a];0===l||10===l?((1===a?ja:ka)(pa(p,0)),p.length=0):p.push(l)}e+=g}G[d>>2]=e;return 0},z:function(){}};
(function(){function a(e){h.asm=e.exports;ma=h.asm.N;Ea();Fa=h.asm.T;Ha.unshift(h.asm.O);J--;h.monitorRunDependencies&&h.monitorRunDependencies(J);0==J&&(null!==Ka&&(clearInterval(Ka),Ka=null),La&&(e=La,La=null,e()))}function b(e){a(e.instance)}function c(e){return Qa().then(function(f){return WebAssembly.instantiate(f,d)}).then(function(f){return f}).then(e,function(f){ka("failed to asynchronously prepare wasm: "+f);D(f)})}var d={a:bc};J++;h.monitorRunDependencies&&h.monitorRunDependencies(J);if(h.instantiateWasm)try{return h.instantiateWasm(d,
a)}catch(e){return ka("Module.instantiateWasm callback failed with error: "+e),!1}(function(){return la||"function"!==typeof WebAssembly.instantiateStreaming||Na()||K.startsWith("file://")||"function"!==typeof fetch?c(b):fetch(K,{credentials:"same-origin"}).then(function(e){return WebAssembly.instantiateStreaming(e,d).then(b,function(f){ka("wasm streaming compile failed: "+f);ka("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ba);return{}})();
h.___wasm_call_ctors=function(){return(h.___wasm_call_ctors=h.asm.O).apply(null,arguments)};var ac=h._malloc=function(){return(ac=h._malloc=h.asm.P).apply(null,arguments)},fb=h.___getTypeName=function(){return(fb=h.___getTypeName=h.asm.Q).apply(null,arguments)};h.___embind_register_native_and_builtin_types=function(){return(h.___embind_register_native_and_builtin_types=h.asm.R).apply(null,arguments)};var T=h._free=function(){return(T=h._free=h.asm.S).apply(null,arguments)};
h.dynCall_jiji=function(){return(h.dynCall_jiji=h.asm.U).apply(null,arguments)};var cc;La=function dc(){cc||ec();cc||(La=dc)};
function ec(){function a(){if(!cc&&(cc=!0,h.calledRun=!0,!na)){Ra(Ha);aa(h);if(h.onRuntimeInitialized)h.onRuntimeInitialized();if(h.postRun)for("function"==typeof h.postRun&&(h.postRun=[h.postRun]);h.postRun.length;){var b=h.postRun.shift();Ia.unshift(b)}Ra(Ia)}}if(!(0<J)){if(h.preRun)for("function"==typeof h.preRun&&(h.preRun=[h.preRun]);h.preRun.length;)Ja();Ra(Ga);0<J||(h.setStatus?(h.setStatus("Running..."),setTimeout(function(){setTimeout(function(){h.setStatus("")},1);a()},1)):a())}}h.run=ec;
if(h.preInit)for("function"==typeof h.preInit&&(h.preInit=[h.preInit]);0<h.preInit.length;)h.preInit.pop()();ec();


  return Rive.ready
}
);
})();
export default Rive;
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import { HARD_MODE_DESCRIPTION, HIGH_CONTRAST_MODE_DESCRIPTION } from '../../constants/strings'
import { getProlificID, resetPage } from '../../lib/localStorage'
import React from 'react'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
                                isOpen,
                                handleClose,
                                isHardMode,
                                handleHardMode,
                                isDarkMode,
                                handleDarkMode,
                                isHighContrastMode,
                                handleHighContrastMode
                              }: Props) => {

  return (
    <BaseModal title='Settings' isOpen={isOpen} handleClose={handleClose} showCloseButton={true}
    >
      <div className='flex flex-col mt-2 divide-y'>
        <SettingsToggle
          settingName='Hard Mode'
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName='Dark Mode'
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName='High Contrast Mode'
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        {!getProlificID() &&
        <button
          type='button'
          className='mt-8 w-full rounded-md border border-transparent shadow-sm px-4 py-4 bg-green-600 font-bold font-large text-white hover:bg-green-700 focus:outline-none dark:bg-green-500 disabled:bg-gray-400'
          onClick={() => {
            resetPage()
          }}
        >
          ⚠ ️Reset Page ⚠ ️
        </button>
        }

      </div>
    </BaseModal>
  )
}

import { BaseModal } from '../BaseModal'
import { Cell } from '../../grid/Cell'
import { resetPage } from '../../../lib/localStorage'
import React from 'react'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const DemoCompletedModal = ({ isOpen, handleClose }: Props) => {

  const pClass = 'mb-3'
  return (
    <BaseModal title='Demo Complete!' isOpen={isOpen} handleClose={handleClose} showCloseButton={false}
               isCancelable={false} wide={true}
    >
      <div className=' text-gray-800 dark:text-white'>

        <div className='flex justify-center mb-1 mt-8'>
          <Cell isRevealing={true} isCompleted={true} status='present' value='W' />
          <Cell isRevealing={true} value='O' />
          <Cell isRevealing={true} isCompleted={true} status='correct' value='R' />
          <Cell isRevealing={true} isCompleted={true} status='absent' value='D' />
          <Cell isRevealing={true} isCompleted={true} status='correct' value='L' />
          <Cell isRevealing={true} value='E' />
        </div>
        <div className='flex justify-center mb-1 mt-4'>
          <Cell isRevealing={true} isCompleted={true} status='absent' value='L' />
          <Cell isRevealing={true} value='A' />
          <Cell isRevealing={true} isCompleted={true} status='correct' value='B' />
        </div>

        <p className={pClass + ' text-lg mt-8'}>We hope you liked the demo!</p>
        <p className={pClass + ' mt-2 mx-10'}>You're welcome to keep playing bonus rounds if you want, or you can reset
          your demo and
          try a new study arm!</p>

        <p className={' mt-2 mb-2 text-[.9rem] text-gray-800 dark:text-white'}>(You can also always reset your demo
          using the ⚙️ Settings Menu any time
          you
          want!)</p>

        <button
          autoFocus={true}
          type='button'
          className='mt-7 max-w-sm w-full rounded-md border border-transparent shadow-sm px-4 py-4 bg-green-600 font-bold font-large text-white hover:bg-green-700 focus:outline-none dark:bg-green-500'
          onClick={() => {
            resetPage()
          }}
        >
          Reset Page
        </button>


        <button
          autoFocus={true}
          type='button'
          className=' max-w-sm w-full mt-3 mb-5 rounded-md dark:bg-slate-800 dark:text-green-400 dark:outline-2 text font-bold px-9 py-5 bg-white outline outline-1 outline-green-600 text-green-600 hover:text-green-700 cursor-pointer'
          onClick={() => {
            handleClose()
          }}
        >
          Keep Playing!
        </button>


        <p className={' mt-1 mx-10 mb-4'}>Please feel free to check out the <a
          href='https://github.com/MITMediaLabAffectiveComputing/WordleLab' target='_blank'
          className='text-green-600 dark:text-green-300'>open source code</a> or reach
          out to us at <a href='mailto:wordlelab@media.mit.edu'
                          className='text-green-600 dark:text-green-300'>wordlelab@media.mit.edu</a>.</p>

      </div>


    </BaseModal>
  )
}

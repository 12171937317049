import { BaseModal } from '../BaseModal'
import React from 'react'
import { logPresurveyEvent, markCanLogSurveyEvents } from '../../../lib/logging'
import { Cell } from '../../grid/Cell'
import { useController, UseControllerProps, useForm } from 'react-hook-form'
import { TriggerFormInitially } from './PreSurveyModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

interface ToggleInputProps extends UseControllerProps {
  labels: Array<string>
  values: Array<string>
  question: string
}

export function ToggleInput(props: ToggleInputProps) {
  const { field, fieldState } = useController(props)
  return (
    <div>
      <div
        className={'text-gray-800 text-lg px-1 text-center mb-2 dark:text-white  max-w-sm mx-auto'}>{props.question}</div>
      <div className='align-top grid grid-cols-2 pt-1 gap-x-[1px] gap-y-1 mb-6 max-w-sm mx-auto'
           style={{ 'columnFill': 'balance' }}>
        {props.labels.map(function(label, i) {
          return <label key={i}
                        className={
                          'inline-block dark:text-white text-center block w-full mb-0.5 mt-1 cursor-pointer mx-auto outline px-4 py-2 outline-1 rounded'
                          + (field.value == props.values[i] ? ' bg-green-600 outline-green-600 text-white' : ' outline-slate-300 dark:outline-green-300')
                          + (i == 0 ? ' rounded-r-none' : ' rounded-l-none')
                        }>
            <input className='w-0' {...field} value={props.values[i]} type='radio' />
            <span className=''>{label}</span>
          </label>
        })}
      </div>
    </div>
  )
}

export const DemoIntroModal = ({ isOpen, handleClose }: Props) => {

  const { register, handleSubmit, watch, trigger, formState: { errors }, control, getValues } = useForm({ mode: 'all' })

  React.useEffect(() => {
    if (isOpen) {
      markCanLogSurveyEvents()
    }
    logPresurveyEvent('demointro', isOpen)
  }, [isOpen])

  const formIsValid = Object.keys(errors).length == 0

  const pClass = 'mb-3'
  return (
    <BaseModal title='Welcome to the WordleLab Demo!' isOpen={isOpen} handleClose={handleClose} showCloseButton={false}
               isCancelable={false} wide={true}
    >
      <div className='flex justify-center mb-1 mt-6'>
        <Cell value='W' />
        <Cell value='E' status='present' />
        <Cell value='L' />
        <Cell isRevealing={true} isCompleted={true} value='C' status='correct' />
        <Cell value='O' />
        <Cell value='M' isCompleted={true} status='absent' />
        <Cell value='E' status='correct' />
      </div>

      <div className=' text-gray-800 dark:text-gray-300 mt-6'>

        <p className={pClass}>This is a demo of WordleLab, an <a
          href='https://github.com/MITMediaLabAffectiveComputing/WordleLab' target='_blank'
          className='text-green-600 dark:text-green-300'>open source</a> research
          project by the MIT
          Media Lab. To start the demo, please select the study arm you wish to simulate.</p>


        <form onSubmit={handleSubmit(() => {
        })}>

          <div className='mt-8 mb-[-1.4rem]'>
            <ToggleInput control={control} name={'priming'}
                         labels={['Anger Priming', 'Control Priming']}
                         values={['anger', 'control']}
                         question='Select Your Demo Study Arm' rules={{ required: true }} />
          </div>

          <ToggleInput control={control} name={'agenttype'}
                       labels={['Empathetic Agent', 'Control Agent']}
                       values={['empathetic', 'control']}
                       question='' rules={{ required: true }} />
        </form>

        <p className={'mt-[-.8rem] max-w-sm mx-auto text-[.9rem] text-gray-700 dark:text-gray-400'}>(Please note real
          participants are randomly assigned arms. This is for demo purposes only!)</p>


        <p className={' mt-10 mb-3'}>If you like what you see and are interested in collaboration, please check out
          our <a href='https://github.com/MITMediaLabAffectiveComputing/WordleLab' target='_blank'
                 className='text-green-600 dark:text-green-300'>open source code</a> or reach out to us
          at <a href='mailto:wordlelab@media.mit.edu'
                className='text-green-600 dark:text-green-300'>wordlelab@media.mit.edu</a>.
        </p>
      </div>
      <div className='mt-5 sm:mt-2 dark:text-white'>
        <button
          autoFocus={true}
          disabled={!formIsValid}
          type='button'
          className='mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-4 bg-green-600 font-bold font-large text-white hover:bg-green-700 focus:outline-none dark:bg-green-500 disabled:bg-gray-400'
          onClick={() => {
            localStorage.setItem('primedEmotion', getValues('priming'))
            localStorage.setItem('enhancedFeedback', getValues('agenttype') === 'empathetic' ? 'true' : 'false')
            handleClose()
          }}
        >
          {formIsValid ? 'Let\'s Begin!' : 'Select Your Study Arm'}
        </button>
      </div>
      <TriggerFormInitially trigger={trigger} />
    </BaseModal>
  )
}
